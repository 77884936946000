import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle, Loader2 } from 'lucide-react';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { authenticate, isAuth, reloadtoken, setCookie, signin } from '../actions/auth';
import { LoadingButton } from './buttons/loading-button';
import InputPassword from './crm/Inputs/input-password';
import { Alert, AlertDescription } from './ui/alert';
import { CustomFormField } from './ui/custom-form-field';
import { Form } from './ui/form';
import { Input } from './ui/input';

const zSchemaLogin = z.object({
  email: z.string().email({ message: 'El email debe de ser válido.' }),
  password: z.string().min(4, { message: 'La contraseña debe de contener por lo menos 4 caracteres.' })
})

type LoginFormSchema = z.infer<typeof zSchemaLogin>

const defaultValues: LoginFormSchema = { email: '', password: '' }

const LoginPage = () => {
  const router = useRouter();
  const [alertLogin, setAlertLogin] = useState<{ message: string, error: string }>({ message: "", error: "" });

  const contextForm = useForm<LoginFormSchema>({
    resolver: zodResolver(zSchemaLogin),
    defaultValues
  })

  useEffect(() => {
    const email = router?.query?.email as string;
    const password = router?.query?.password as string;
    contextForm.reset({ email: email || "", password: password || "" })
  }, [router?.query]);

  const { mutate, isPending } = useMutation({
    mutationFn: signin,
    onSuccess: (response) => {
      console.log('HOLA', response);

      if (response.error) return setAlertLogin({ ...alertLogin, message: "", error: response.error });

      setAlertLogin({
        ...alertLogin,
        message: "Inicio de sesión exitoso, redireccionando..",
        error: ""
      });

      authenticate(response, () => {
        const returnurl = router.query.returnurl

        const url = !(!!returnurl && returnurl != "/" && typeof returnurl == "string")
          ? _.get(response, "user.startPage") ?? "/"
          : returnurl;

        router.push(url);
      });
    },
    onError: (error) => {
      console.log(error);

      mixpanel.track('Error login web', { error });
      setAlertLogin({ ...alertLogin, message: "", error: "Error" });
    }
  })

  const handleSubmit = async (values: LoginFormSchema) => {
    mutate(values);
  };

  const browser = typeof window !== 'undefined';

  useEffect(() => {
    const { token } = router.query;

    mixpanel.track('Debug login', {
      token,
      browser,
      query: router.query,
      condition: token && browser
    });

    if (token && browser) {
      setCookie("token", token);
      setCookie("onApp", "true");

      reloadtoken().then((res) => {
        mixpanel.track('Debug login', {
          registered: !!res,
          response: res
        });

        if (!res || res.error) { router.push("/login") }

        authenticate(res, async () => {
          mixpanel.track('Debug login', {
            authenticate: true
          });

          router.push("/")
        });
      });
    }

    if (isAuth()) { router.push("/") }
  }, [router?.query]);

  const { error } = alertLogin || {};

  if (browser && router?.query?.token) {
    return (
      <>
        <div className='w-full h-[100vh] flex justify-center items-center'>
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      </>
    )
  }

  return (
    <>
      <div>
        <h2 className="text-[27px] font-semibold tracking-tight">Hola, bienvenido a Ventia 👋</h2>
        <p className="text-[17px] tracking-tight  font-normal text-neutral-500">Iniciar sesión</p>
      </div>
      <div>
        <div className='min-h-[50px] py-2'>
          {error && <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>}
        </div>
      </div>
      <Form {...contextForm}>
        <form
          onSubmit={contextForm.handleSubmit(handleSubmit)}
          className='space-y-6'
        >
          <CustomFormField
            control={contextForm.control}
            label='Correo electrónico'
            name="email"
            isRequired
            fnElement={({ field }) => (
              <Input
                placeholder="ejemplo@gmail.com"
                className='py-[22px]'
                type='email'
                required
                autoFocus
                {...field}
              />
            )}
          />

          <CustomFormField
            control={contextForm.control}
            label='Contraseña'
            name="password"
            isRequired
            fnElement={({ field }) => (
              <InputPassword
                placeholder="*************"
                className='py-[22px] pr-[70px]'
                autoComplete='off'
                {...field}
                required
              />
            )}
          />

          <LoadingButton
            type='submit'
            loading={isPending}
            className='w-full mt-7 py-[22px]'
          >
            Ingresar
          </LoadingButton>
        </form>
      </Form>
    </ >
  )
};

export default LoginPage;